<template src="./template.html"></template>

<script>
export default {
  name: "reset-password",
  data() {
    return {
      email: null,
      code: null,
      out: null,
      password: null,
      confirmPass: null,
      isSuccess: false,
      isLoading: false
    }
  },
  mounted() {
    this.isSuccess = false
    this.email = this.$route.query.email
    this.code = this.$route.query.token
    this.out = this.$route.query.out
  },
  methods: {
    async setNewPassword() {
      if (this.$refs.pass.isValid && this.$refs.confirmPass.isValid) {
        if (this.code && this.password && this.confirmPass) {
          if (this.password !== this.confirmPass) {
            this.showError('Lozinke nisu identične')
            return
          }
          this.isLoading = true
          let data = {
            email: this.email,
            token: this.code,
            password1: this.password,
            password2: this.confirmPass,
          }
          if(this.out === 'true'){
            let response = await this.$store.dispatch('resetPassword', data)
            console.log('Reset response: ', response)
            if(response && response.success){
              this.isSuccess = true
            }else{
              if(response.error.code === 'ACTIVATION_TOKEN_EXPIRED'){
                this.showError('Greška! Token za resetovanje lozinke je istekao.')
              }else if(response.error.code === 'INVALID_CONFIRM_TOKEN'){
                this.showError('Greška! Neispravan token za resetovanje lozinke.')
              }else{
                this.showError('Došlo je do greške. Molimo pokušajte kasnije.')
              }

            }
          }else if(this.out === 'false'){
            let response = await this.$store.dispatch('resetPasswordIndoor', data)
            console.log('Reset response: ', response)
            if(response && response.success){
              this.isSuccess = true
            }else{
              if(response.error.code === 'ACTIVATION_TOKEN_EXPIRED'){
                this.showError('Greška! Token za resetovanje lozinke je istekao.')
              }else if(response.error.code === 'INVALID_CONFIRM_TOKEN'){
                this.showError('Greška! Neispravan token za resetovanje lozinke.')
              }else{
                this.showError('Došlo je do greške. Molimo pokušajte kasnije.')
              }
            }
          }
          this.isLoading = false
        }
      }
    },
    showError(message) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      })
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>