<template src="./template.html"></template>
<script>
export default {
  name: 'Login-Page',
  data() {
    return {
      username: null,
      password: null,
      isLoading: false,
      isActivated: false,
      errorMessage: null
    }
  },
  async created(){
  },
  async mounted() {
    this.isLoading = true
    setTimeout(async ()=>{
      console.log('Path: ', this.$router.currentRoute.query.token)
      let response = await this.$store.dispatch('activateAccount', this.$router.currentRoute.query.token)
      if(response && response.success){
        this.isActivated = true
      }else{
        if(response.error.code === 'ACTIVATION_TOKEN_EXPIRED'){
          this.errorMessage = 'Greška! Aktivacioni token je istekao.'
        }else if(response.error.code === 'INVALID_CONFIRM_TOKEN'){
          this.errorMessage = 'Greška! Neispravan aktivacioni token.'
        }else{
          this.errorMessage = 'Došlo je do greške. Molimo pokušajte kasnije.'
        }
        this.loginError(response.error)
      }
      console.log('Response: ', response)
      this.isLoading = false
    }, 1500)
  },
  methods: {
    loginError(error) {
      this.$emit('showLoading', false)
      this.$buefy.notification.open({
        duration: 5000,
        message: error.message,
        type: 'is-danger',
        hasIcon: false
      })
    }
  }
}
</script>
<style scoped lang="scss" src="./style.scss"></style>
