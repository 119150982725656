//Vue & Vuex
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//Map import
//Axios
import Axios from 'axios'
//Buefy import
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
//SCSS Custom theme
import './main.scss'

console.warn('%c CallCar is the owner of this project, it is not safe to modify the content of the page.!', 'color: red; font-size: 30px; font-weight: bold;');

/**
 * Global HTTP
 * @type {AxiosStatic}
 */
Vue.prototype.$http = Axios

/**
 * Access to ENV file
 * @type {{readonly VUE_APP_API_URL?: string, readonly VUE_APP_TITLE?: string, readonly VUE_APP_ACCESS_TOKEN?: string, readonly NODE_ENV?: string, readonly VUE_APP_SIGN_UP_RECAPTCHA_KEY?: string, readonly VUE_APP_IDENTITY_POOL_ID?: string, readonly VUE_APP_REGION?: string, readonly VUE_APP_USER_POOL_WEB_CLIENT_ID?: string, readonly VUE_APP_USER_POOL_ID?: string, readonly PATH?: string, readonly __CFBundleIdentifier?: string, readonly SHELL?: string, readonly CONDA_DEFAULT_ENV?: string, readonly CONDA_EXE?: string, readonly OLDPWD?: string, readonly CONDA_PYTHON_EXE?: string, readonly USER?: string, readonly TMPDIR?: string, readonly COMMAND_MODE?: string, readonly SSH_AUTH_SOCK?: string, readonly CONDA_PREFIX?: string, readonly _CE_CONDA?: string, readonly XPC_FLAGS?: string, readonly _CE_M?: string, readonly __CF_USER_TEXT_ENCODING?: string, readonly CONDA_PROMPT_MODIFIER?: string, readonly LOGNAME?: string, readonly LC_CTYPE?: string, readonly XPC_SERVICE_NAME?: string, readonly HOME?: string, readonly CONDA_SHLVL?: string, [p: string]: string}}
 */
Vue.prototype.$env = process.env
/**
 * Amplify configuration
 */

Vue.config.productionTip = true

/**
 * Vue enable plugins
 */
Vue.use(Buefy)

/**
 * Vue start
 */
new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount('#app')
