import Vue from 'vue'
import VueRouter from 'vue-router'
import confirmAccount from '@/views/confirm-account/confirm-account.vue'
import resetPassword from "@/views/reset-password/reset-password.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/confirm-account',
        name: 'confirm-account',
        meta:{
            title: 'Confirm account'
        },
        component: confirmAccount
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta:{
            title: 'Reset password',
            hasMenu: false,
            isAuthPage: true
        },
        component: resetPassword
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
export default router
