import Vue from "vue";
export default {
    activateAccount: async ({commit}, token)=>{
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/client_users/account/activate/' + token
        };
        return await Vue.prototype.$http(config).then(function (response) {
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    resetPassword: async ({commit}, dataIn)=>{

        const data = new URLSearchParams();
        data.append('email', dataIn.email);
        data.append('token', dataIn.token);
        data.append('password1', dataIn.password1);
        data.append('password2', dataIn.password2);

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/client_users/password/reset',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        return await Vue.prototype.$http(config).then(function (response) {
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    resetPasswordIndoor: async ({commit}, dataIn)=>{

        const data = new URLSearchParams();
        data.append('email', dataIn.email);
        data.append('token', dataIn.token);
        data.append('password1', dataIn.password1);
        data.append('password2', dataIn.password2);

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/users/password/reset/auth',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        return await Vue.prototype.$http(config).then(function (response) {
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
}

