<template>
  <div>
    <div>
      <transition v-if="!$router.currentRoute.meta.isAuthPage" name="fade" mode="out-in">
        <main :class="{'main-style' : $router.currentRoute.meta.hasMenu}" :key="$router.currentRoute.path">
          <slot/>
        </main>
      </transition>
      <main v-else :class="{'main-style' : $router.currentRoute.meta.hasMenu}">
        <slot/>
      </main>
    </div>
    <footer class="footer app-footer">
      <div class="content is-flex">
        <div style="margin-top: 4px;" class="ml-auto mr-auto">
          Powered by <a href="https://callcar.rs/" style="color: rgba(255,255,255,0.8)">CallCar</a> @ 2023
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  name: 'LayoutDefault',
  components: {},
  data() {
    return {
      isEditProfileActive: false
    };
  },
  mounted() {
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.bottom-shadow {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.main-style {
  min-height: calc(100vh - 30px - 90px);
  background: #f9f9f9;
}
</style>
